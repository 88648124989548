import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby'


const Accommodation = () => (
    <StaticQuery query={graphql`
    {
        home: wordpressPage(title: { eq: "Home" }) {
            acf {
                bottom_background_image {
                    source_url
                }
            }
        }
        rooms: wordpressPage(slug: { eq: "rooms-rates" }) {
            acf {
                accommodation_heading
                accommodation_subheading
            }
        }
        allWordpressWpAccommodations {
            edges {
                node {
                    id
                    title
                    featured_media {
                        source_url
                    }
                    acf {
                        button_text
                        button_link
                    }
                    acc_category
                }
            }
        }
    }
    `} render={props => (
        <div className="frame">
            <div className="home-accommodations rooms-accommodations">
                <div className="subtitle-title">
                    <div className="subtitle" data-aos="fade-up">{ props.rooms.acf.accommodation_heading }</div>
                    <div className="title" data-aos="fade-up" data-aos-delay="100">{ props.rooms.acf.accommodation_subheading }</div>
                    <div className="line line2" data-aos="fade-up" data-aos-delay="200"></div>
                </div>
                <div className="all-accommodation"> 
                {
                    props.allWordpressWpAccommodations.edges.map((item, index) => (
                    (item.node.acc_category.includes(9)) ? (
                        <div className="item tiles" key={item.node.id} data-aos="fade-up" data-aos-delay={ index * 400 } data-aos-duration="1200">
                            <div className="itemhover">
                                <Link to={item.node.acf.button_link}>
                                    <div className="title">{item.node.title}</div>
                                    <div className="image">
                                        <img src={item.node.featured_media.source_url} />
                                    </div>
                                    <div className="button">
                                        <Link to={item.node.acf.button_link}>{ item.node.acf.button_text }</Link>
                                    </div>
                                </Link>
                            </div>
                        </div>) : null     
                    ))
                }
                </div>
                <div className="line line3"></div>
                <div className="bottom-background-image" >
                    <img src={props.home.acf.bottom_background_image.source_url} />
                </div>
            </div>
        </div>
    ) } />
);

export default Accommodation;