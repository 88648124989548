import React, { useState, useEffect, useRef } from 'react';
import { graphql, StaticQuery } from 'gatsby';

const Rooms = () => {

    const [state, setState] = useState({
        currentIndex: null
    });

    function changeCurrentIndex(index) {
        // document.body.style.overflow = 'hidden';
        setState({
            currentIndex: index
        });
    };

    const node = useRef();

    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return () => {
        document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {
        if(node.current.contains(e.target)) {
            // inside click
            return;
        }
        // outside click 
        // document.body.style.overflow = 'unset';
        setState({
            currentIndex: null
        });
    };

    return <StaticQuery query={graphql`
    {
        wordpressPage(slug: { eq: "rooms-rates" }) {
            acf {
                more_info_button
                book_now_button
                content_left
                content_right
                cancellation_fee_title
                cancellation_fee_content
                covid19_precautions_heading
                covid19_precautions_left
                covid19_precautions_right
            }
        }
        allWordpressWpRooms {
            edges {
                node {
                    id
                    title
                    slug
                    featured_media {
                        source_url
                    }
                    acf {
                        price_info_1___title
                        price_info_1___subtitle
                        price_info_1___price
                        price_info_2___title
                        price_info_2___subtitle
                        price_info_2___price
                        price_info_3___title
                        price_info_3___subtitle
                        price_info_3___price
                        popup_large_image {
                            source_url
                        }
                        popup_small_image {
                            source_url
                        }
                        superior_title_content
                    }
                }
            }
        }
    }
    `} render={props => (
        <div className="frame">
            <div className="rooms-section">
                {
                    props.allWordpressWpRooms.edges.map((item, index) => (
                        <div className="item tiles" key={item.node.id} data-aos="fade-up" data-aos-delay={ index * 400 } data-aos-duration="1200">
                            <div className="itemhover">
                                <div className="title">{item.node.title}</div>
                                <div className="image">
                                    <img onClick={() => changeCurrentIndex(index)} src={item.node.featured_media.source_url} />
                                </div>
                                <div className="price-info">
                                    <div className="price-info-details">
                                        <div className="price-title">{item.node.acf.price_info_1___title}</div>
                                        <div className="subtitle">{item.node.acf.price_info_1___subtitle}</div>
                                        <div className="price">{item.node.acf.price_info_1___price}</div>
                                    </div>
                                    <hr />
                                    <div className="price-info-details">
                                        <div className="price-title">{item.node.acf.price_info_2___title}</div>
                                        <div className="subtitle">{item.node.acf.price_info_2___subtitle}</div>
                                        <div className="price">{item.node.acf.price_info_2___price}</div>
                                    </div>
                                    <hr />
                                    <div className="price-info-details">
                                        <div className="price-title">{item.node.acf.price_info_3___title}</div>
                                        <div className="subtitle">{item.node.acf.price_info_3___subtitle}</div>
                                        <div className="price">{item.node.acf.price_info_3___price}</div>
                                    </div>
                                </div>
                                <div className="btn-info">
                                    <div className="more-info"><a onClick={() => changeCurrentIndex(index)}>{ props.wordpressPage.acf.more_info_button }</a></div>
                                    <div className="book-now"><a href="https://www.nightsbridge.co.za/bridge/Search?bbid=11280" target="_blank">{ props.wordpressPage.acf.book_now_button }</a></div>
                                </div>
                            </div>
                        </div> 
                    ))
                }
                <div className="content-wrapper" data-aos="fade-up">
                    <div className="content" dangerouslySetInnerHTML = {{ __html: props.wordpressPage.acf.content_left }}></div>
                    <div className="content" dangerouslySetInnerHTML = {{ __html: props.wordpressPage.acf.content_right }}></div>
                </div>
                {/* <div className="covid19-precautions" data-aos="fade-up">
                    <div className="covid19-heading">{ props.wordpressPage.acf.covid19_precautions_heading }</div>
                    <div className="content-wrapper">
                        <div className="content" dangerouslySetInnerHTML = {{ __html: props.wordpressPage.acf.covid19_precautions_left }}></div>
                        <div className="content" dangerouslySetInnerHTML = {{ __html: props.wordpressPage.acf.covid19_precautions_right }}></div>
                    </div>
                </div> */}
                <div className="cancellation-section" data-aos="fade-up">
                    <div className="can-title">{props.wordpressPage.acf.cancellation_fee_title}</div>
                    <div className="can-content" dangerouslySetInnerHTML = {{ __html: props.wordpressPage.acf.cancellation_fee_content}}></div>
                </div>
                <div className="rooms-popup fade-in" key={state.currentIndex !== null ? props.allWordpressWpRooms.edges[state.currentIndex].node.id : null} style={{display: state.currentIndex !== null ? 'flex' : 'none' }}>
                    <div className="rooms-popup-inner" ref={node}>
                        <div className="item-left">
                            <img src={ state.currentIndex !== null ? props.allWordpressWpRooms.edges[state.currentIndex].node.acf.popup_large_image.source_url : null} alt="" />
                        </div>
                        <div className="item-right">
                            <img src={ state.currentIndex !== null ? props.allWordpressWpRooms.edges[state.currentIndex].node.acf.popup_small_image.source_url : null} alt="" />
                        </div>
                        <div className="popup-title">{ state.currentIndex !== null ? props.allWordpressWpRooms.edges[state.currentIndex].node.title : null}</div> 
                        <div className="price-info">
                            <div className="price-info-details">
                                <div className="price-title">{state.currentIndex !== null ? props.allWordpressWpRooms.edges[state.currentIndex].node.acf.price_info_1___title : null }</div>
                                <div className="subtitle">{state.currentIndex !== null ? props.allWordpressWpRooms.edges[state.currentIndex].node.acf.price_info_1___subtitle : null }</div>
                                <div className="price">{state.currentIndex !== null ? props.allWordpressWpRooms.edges[state.currentIndex].node.acf.price_info_1___price : null }</div>
                            </div>
                            <hr />
                            <div className="price-info-details">
                                <div className="price-title">{state.currentIndex !== null ? props.allWordpressWpRooms.edges[state.currentIndex].node.acf.price_info_2___title : null }</div>
                                <div className="subtitle">{state.currentIndex !== null ? props.allWordpressWpRooms.edges[state.currentIndex].node.acf.price_info_2___subtitle : null }</div>
                                <div className="price">{state.currentIndex !== null ? props.allWordpressWpRooms.edges[state.currentIndex].node.acf.price_info_2___price : null }</div>
                            </div>
                            <hr />
                            <div className="price-info-details">
                                <div className="price-title">{state.currentIndex !== null ? props.allWordpressWpRooms.edges[state.currentIndex].node.acf.price_info_3___title : null }</div>
                                <div className="subtitle">{state.currentIndex !== null ? props.allWordpressWpRooms.edges[state.currentIndex].node.acf.price_info_3___subtitle : null }</div>
                                <div className="price">{state.currentIndex !== null ? props.allWordpressWpRooms.edges[state.currentIndex].node.acf.price_info_3___price : null }</div>
                            </div>
                        </div>
                        <div className="popup-content" dangerouslySetInnerHTML = {{ __html: state.currentIndex !== null ? props.allWordpressWpRooms.edges[state.currentIndex].node.acf.superior_title_content : null}}></div> 
                        <div className="book-now-wrapper">
                            <div className="book-now"><a href="https://www.nightsbridge.co.za/bridge/Search?bbid=11280" target="_blank">{ props.wordpressPage.acf.book_now_button }</a></div>
                        </div>
                    </div>
                </div>
            </div>
            
         </div>
    ) } />
};

export default Rooms;