import React, { Component } from "react"
import { graphql, StaticQuery } from 'gatsby';
import '../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';

const OwlCarousel = React.lazy(() =>
    import('react-owl-carousel')
)
const isSSR = typeof window === "undefined"

class ReviewsSlider extends Component {

    state= {
        responsive:{
            0: {
                items: 1,
            },
            768: {
                items: 2
            }
        }
    }

    render() {
    return <StaticQuery query={graphql`
    {
        wordpressPage(title: { eq: "Home" }) {
            acf {
                reviews_subtitle
                reviews_title
            }
        }
        allWordpressWpReviews {
          edges {
            node {
              id
              title
              content
              acf {
                date
                country
              }
            }
          }
        }
    }

    `} render = {props => (
        <div className="frame">
            <div className="home-reviews rooms-reviews">
                <div className="subtitle-title">
                    <div className="line line1" data-aos="fade-up"></div>
                    <div className="subtitle" data-aos="fade-up">{ props.wordpressPage.acf.reviews_subtitle }</div>
                    <div className="title" data-aos="fade-up">{ props.wordpressPage.acf.reviews_title }</div>
                    <div className="line line2" data-aos="fade-up"></div>
                </div>
                <div className="all-reviews" data-aos="fade-up" >
                {!isSSR && (
                <React.Suspense fallback={<div />}>
                    <OwlCarousel
                    className="owl-theme"
                    loop
                    nav
                    items = {2}
                    responsive={this.state.responsive} 
                >
                {
                    props.allWordpressWpReviews.edges.map(reviewItem => (
                    <div className="item" key={reviewItem.node.id}>
                        <div className="title">{reviewItem.node.title}</div>
                        <div className="content" dangerouslySetInnerHTML = {{ __html: reviewItem.node.content }}></div>
                        <div className="location-country">
                            <span className="location">{reviewItem.node.acf.date}</span>, 
                            <span className="country">&nbsp;{reviewItem.node.acf.country}</span>
                        </div>
                     </div>   
                    ))
                }
                 </OwlCarousel>
                </React.Suspense>
                )}
                </div>
            </div>
        </div>
    ) } />
}
};

export default ReviewsSlider;