import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import arrow from "../../images/arrow.png";

const Content = () => (
    <StaticQuery query={graphql`
    {
        rooms: wordpressPage(slug: { eq: "rooms-rates" }) {
                title
                acf {
                    subtitle
                    content
                    top_heading
                    main_heading
                    main_content
                    main_content_2
                }
        }
        home: wordpressPage(title: { eq: "Home" }){
            acf {
                top_background_image {
                    source_url
                }
            }
        }
    }
    `} render={props => (
        <div className="frame">
             <div className="room-rates-content">
                <div className="top-background-image">
                    <img src={props.home.acf.top_background_image.source_url} alt="background-image" />
                </div>
                <div className="title" data-aos="fade-up" dangerouslySetInnerHTML = {{ __html: props.rooms.title}}></div>
                <div className="subtitle" data-aos="fade-up">{ props.rooms.acf.subtitle } </div>
                <div className="top-heading" data-aos="fade-up" dangerouslySetInnerHTML = {{ __html:props.rooms.acf.top_heading }}></div>
                <div className="heading" data-aos="fade-up" dangerouslySetInnerHTML = {{ __html:props.rooms.acf.main_heading }}></div>
                <div className="main-content" data-aos="fade-up" dangerouslySetInnerHTML = {{ __html: props.rooms.acf.main_content}}></div>
                <div className="main-content-2" data-aos="fade-up" dangerouslySetInnerHTML = {{ __html: props.rooms.acf.main_content_2}}></div>
            </div>
        </div>
    ) } />
);

export default Content;