import React, { Component } from 'react'
import DefaultTemplate from "../templates/default"
import Banner from "../components/room-rates/banner"
import Content from "../components/room-rates/content"
import Rooms from "../components/room-rates/rooms"
import Reviews from "../components/room-rates/reviews"
import Accommodation from "../components/room-rates/accommodation"
import AOS from 'aos';
import 'aos/dist/aos.css';

class RoomsRates extends Component {
    
    componentDidMount(){
        AOS.init({
            duration : 2000,
            once: true
        })
    }
    render(){
        return  <DefaultTemplate>
                    <Banner />
                    <Content />
                    <Rooms />
                    <Reviews />
                    <Accommodation />
                </DefaultTemplate>
    }
}
   
export default RoomsRates